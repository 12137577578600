import { Button } from "primereact/button";

export const Footer = () => {
    return (
        <>
            <div className="text-left ">
                <p>Tekong Seafood Restaurant 6 Changi Village Rd, Singapore 509907</p>
                {/* <Button
                className="mt-4 shadow-md text-lg p-2 hover:scale-110 transition-all"
                onClick={() => {
                    window.open("https://www.facebook.com/tekongseafood/");
                }}
                >
                <span className="mr-2 pi pi-facebook text-xl" style={{ color: "blue" }} />
                <p className="ml-2">Facebook</p>
            </Button> */}
            </div>
        </>
    );
};
